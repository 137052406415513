@content0: m-content0;

.@{content0}-wrapper {
  min-height: 446px;
  overflow: hidden;

  .@{content0} {
    height: 100%;
    padding: 34px 15px 20px;

    >.title-wrapper {
      margin: 0 auto 28px;
    }

    &-block {
      display: inline-block;
      text-align: center;
      min-height: 180px;
      img {
        width: 100%;
      }

      .wrap-box{
        display: flex;
      }

      .left-box{
        width: 85px;
        height: auto;
      }

      .right-box{
        flex:1;
        text-align: left;
        margin-left: 10px;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        width: 40px;
        height: 40px;
        margin: auto;
      }

      &-title {
        line-height: 32px;
        margin: 10px auto;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content0}-wrapper {
    min-height: 446px;
  }
}
