@content8: content8;
.@{content8}-wrapper {
  &.home-page-wrapper {
    overflow: initial;
    width: ~"calc(100% - 112px)";
    min-height: 878px;
    margin: auto;
    border-radius: 4px;
    box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
    background: #fff;
    & .home-page {
      margin-top: -220px;
      padding: 64px 24px;
      overflow: hidden;
    }
  }
  .content-wrapper {
    margin-top: 72px;
    .@{content8}-block-wrapper {
      margin-bottom: 60px;
    }
    .@{content8}-block {
      width: 100%;
      max-width: 192px;
      margin: auto;
    }
    .@{content8}-img {
      border-radius: 8px;
      overflow: hidden;
      width: 100%;
      img {
        width: 100%;
        display: block;
        &[src=""] {
          background: linear-gradient(to top right, #d6defc, #fff);
          padding-bottom: 100%;
        }
      }
    }
    .@{content8}-title {
      font-size: 20px;
      color: #0d1a26;
      font-weight: 400;
      margin: 24px auto 8px;
      text-align: center;
      white-space: nowrap;
    }
    .@{content8}-content {
      text-align: center;
      white-space: nowrap;
      font-size: 14px;
      color: #697b8c;
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content8}-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
    .@{content8} {
      &.home-page {
        margin: auto;
        padding-bottom: 0;
      }
    }
    .content-wrapper {
      .@{content8}-block {
        max-width: 240px;
      }
    }
  }
}
